/**
 * Component to add the cookie consent to every page
 */
//import classes from '@styles/common/scss/_cookie.module.scss';
import classes from '@styles/scss/common/_cookie.module.scss';
import { parseCookies } from 'nookies';
import React, { MouseEvent, useEffect, useState } from 'react';

function preventDefaultAnalytics(e: MouseEvent<HTMLElement>) {
    e.preventDefault();
}

export default function Cookieconsent({ currentLang }: { currentLang: string }): JSX.Element {
    const [bannerState, newBannerState] = useState('hidden');
    const [cookieText, setCookieText] = useState('');
    function hideBanner() {
        document.cookie = 'cookieconsent_status=dismiss;path=/';
        newBannerState('hidden');
    }

    const data = [
        {
            cookieText:
                'We use cookies for various purposes - see our. By using the website you agree to our use of cookies.',
            langChoice: {
                iso_code: 'en'
            }
        },
        {
            cookieText: 'Utilizamos cookies para ofrecerte la mejor experiencia posible',
            langChoice: {
                iso_code: 'es'
            }
        },
        {
            cookieText: 'Wir verwenden Cookies, um Ihnen die allerbeste Erfahrung zu bieten',
            langChoice: {
                iso_code: 'de'
            }
        },
        {
            cookieText: 'Používáme cookies, abychom vám poskytli nejlepší možný zážitek',
            langChoice: {
                iso_code: 'cs'
            }
        },
        {
            cookieText: 'Vi bruger cookies for at give dig den bedst mulige oplevelse',
            langChoice: {
                iso_code: 'da'
            }
        },
        {
            cookieText: 'Käytämme evästeitä tarjotaksemme sinulle parhaan mahdollisen kokemuksen',
            langChoice: {
                iso_code: 'fi'
            }
        },
        {
            cookieText:
                'Nous utilisons des cookies en vue de vous offrir la meilleure expérience possible',
            langChoice: {
                iso_code: 'fr'
            }
        },
        {
            cookieText: 'Sütiket használunk, hogy a lehető legjobb élményt nyújtsuk',
            langChoice: {
                iso_code: 'hu'
            }
        },
        {
            cookieText: 'Usiamo i cookie per offrirti la migliore esperienza possibile',
            langChoice: {
                iso_code: 'it'
            }
        },
        {
            cookieText:
                '私たちはあなたに可能な限り最高の体験を提供するためにクッキーを使用しています。',
            langChoice: {
                iso_code: 'ja'
            }
        },
        {
            cookieText: 'We gebruiken cookies om u de best mogelijke ervaring te geven',
            langChoice: {
                iso_code: 'nl'
            }
        },
        {
            cookieText: 'Vi bruker informasjonskapsler for å gi deg en best mulig opplevelse',
            langChoice: {
                iso_code: 'no'
            }
        },
        {
            cookieText: 'Używamy plików cookie, aby zapewnić jak najlepsze wrażenia',
            langChoice: {
                iso_code: 'pl'
            }
        },
        {
            cookieText: 'Usamos cookies para lhe proporcionar a melhor experiência possível',
            langChoice: {
                iso_code: 'pt'
            }
        },
        {
            cookieText: 'Folosim cookie-uri pentru a vă oferi cea mai bună experiență posibilă',
            langChoice: {
                iso_code: 'ro'
            }
        },
        {
            cookieText: 'Мы используем файлы cookie, чтобы обеспечить вам максимальное удобство',
            langChoice: {
                iso_code: 'ru'
            }
        },
        {
            cookieText: 'Piškotke uporabljamo za zagotavljanje najboljše možne izkušnje',
            langChoice: {
                iso_code: 'sk'
            }
        },
        {
            cookieText: 'Vi använder cookies för att ge dig bästa möjliga upplevelse',
            langChoice: {
                iso_code: 'sv'
            }
        },
        {
            cookieText: '我们使用 cookie 为您提供最佳体验',
            langChoice: {
                iso_code: 'zh'
            }
        }
    ];

    useEffect(() => {
        const cookies = parseCookies();

        if (cookies.cookieconsent_status !== 'dismiss') {
            newBannerState('shown');
        }
        data.map((d) => {
            if (currentLang === d.langChoice.iso_code) {
                setCookieText(d.cookieText);
            }
        });
    }, [currentLang]);

    return (
        <div className={`${classes.cookieConsentBanner} ${bannerState}`} id="cookieConsent">
            <p>
                {cookieText.split('.')[0]}{' '}
                <a
                    href={
                        currentLang === 'en'
                            ? `${process.env.NEXT_PUBLIC_APP_URL}cookie.html`
                            : `${process.env.NEXT_PUBLIC_APP_URL}cookie.html/${currentLang}/`
                    }
                    id="splash-cookieConsent"
                    title="cookie policy"
                    style={{ textDecoration: 'underline' }}>
                    cookie policy.
                </a>
                {cookieText.split('.')[1]
                    ? cookieText.split('.')[1] + '.'
                    : cookieText.split('.')[1]}
            </p>
            <div className={classes.okButton2}></div>
            <div
                className={classes.okButton}
                onClick={hideBanner}
                onKeyDown={hideBanner}
                data-event-category="User Action"
                data-event-action="Cookie-Confirm"
                data-event-label="PAGE"
                role="button"
                tabIndex={0}
                aria-label="accept button">
                <a
                    href="/"
                    onClick={preventDefaultAnalytics}
                    aria-label="analytics"
                    title="analytics"
                    role="button"
                    data-event-category="User Action"
                    data-event-action="Cookie-Confirm"
                    data-event-label="PAGE">
                    OK
                </a>
            </div>
        </div>
    );
}
