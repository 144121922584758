import Cookieconsent from '@components/partials/common/cookieConsent';
import Meta from '@components/partials/common/meta';
import { loadKraken } from 'lib/functions/loadKraken';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

export default function Base({
    children,
    performer,
    currentLang
}: {
    children: React.ReactNode;
    performer: string;
    currentLang: string;
}): JSX.Element {
    const router = useRouter();

    useEffect(() => {
        loadKraken(performer);
    }, [router.asPath, router.locale]);

    return (
        <>
            <Head>
                <Meta />
                <link
                    href="https://fonts.googleapis.com/css2?family=La+Belle+Aurore:wght@400&family=Niramit:wght@400&family=Niramit:wght@700&display=swap"
                    rel="stylesheet"
                />
                <meta name="Rating" content="mature" />
                <meta name="RATING" content="RTA-5042-1996-1400-1577-RTA" />
                <meta name="theme-color" content="#FAFAFA" />
                <link rel="shortcut icon" href="https://assets.camrabbit.sex/favicon-cr.png" />
            </Head>
            {children}
            <Cookieconsent currentLang={currentLang} />
        </>
    );
}
