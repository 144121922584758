export function loadKraken(performer) {
    var KRAKEN = KRAKEN || {};
    KRAKEN.productParams = {
        id: '7311',
        cmp: 'tfid',
        creturn: 'all',
        storage: 'tfid',
        impTrail: 'y'
    };
    KRAKEN.phoenixCallBack = function () {
        KRAKEN.helpers.handleExid();
        // KRAKEN.helpers.AppendRwExid();
        KRAKEN.common.trail();
    };
    KRAKEN.tfidCallback = function () {
        if ('2333' !== KRAKEN.tfidchecker.returnTFdata.tfid) {
            KRAKEN.common.setBrowserCookie('tfid', KRAKEN.tfidchecker.returnTFdata.tfid, 365);
        }
        KRAKEN.helpers.AppendRwTfid();
        KRAKEN.helpers.AppendRwClickId();
        KRAKEN.common.launchPageImpressionsEventLog();
    };
    // added code to load performer
    const loadPerformer = function () {
        // condition needed currently because we use load kraken on user footer links
        if (performer != 'No Performer') {
            var cmp = KRAKEN.tfidchecker.returnTFdata.cmp;
            var exid = KRAKEN.common.getBrowserCookie('u');
            var se = document.createElement('script');
            se.src =
                '//embwmpt.com/embed/lf?c=object_container&site=wl3&cobrandId=208800&psid=rabbit&pstool=202_1&psprogram=cbrnd&campaign_id=' +
                cmp +
                `&category=&forcedPerformers[]=${performer}&vp[showChat]=true&vp[chatAutoHide]=false&vp[showCallToAction]=true&vp[showPerformerName]=true&vp[showPerformerStatus]=true&filters=&ms_notrack=1&subAffId=` +
                exid;
            document.head.appendChild(se);
        }
    };

    KRAKEN.entity = {
        url: window.location.href,
        localStorageFallback: false,
        getKrakenApiUri: function (exid) {
            if (exid.length === 22) {
                return '/identity/' + exid;
            }
            return false;
        },
        getParameterByName: function (name) {
            name = name.replace(/[[\]]/g, '\\$&');
            var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
                results = regex.exec(this.url);
            if (!results) return null;
            if (!results[2]) return '';
            return decodeURIComponent(results[2].replace(/\+/g, ' '));
        },
        getArrayParameterByName: function (name) {
            var values = [];
            name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
            var pattern = name + '=([^&#]+)';
            var o_reg = new RegExp(pattern, 'ig');
            for (;;) {
                var matches = o_reg.exec(this.url);
                if (matches && matches[1]) {
                    values.push(matches[1]);
                } else {
                    break;
                }
            }
            return values;
        },
        addToLocalStorage: function (localHost, key) {
            var value = '';
            if (key == 'tags') {
                value = this.setTagsvaluesForLocalStorage();
            } else {
                value = this.getParameterByName(key);
            }
            if (value != null && value != 'undefined' && value != '') {
                KRAKEN.common.setBrowserLocalStorage(localHost + '_' + key, value);
                return true;
            }
            return false;
        },
        getEntityData: function (localHost) {
            var data = new Object();
            if (typeof Storage !== 'undefined') {
                data.property = KRAKEN.common.getBrowserLocalStorage(localHost + '_' + 'property');
                data.utm_medium = KRAKEN.common.getBrowserLocalStorage(
                    localHost + '_' + 'utm_medium'
                );
                data.utm_source = KRAKEN.common.getBrowserLocalStorage(
                    localHost + '_' + 'utm_source'
                );
                data.utm_campaign = KRAKEN.common.getBrowserLocalStorage(
                    localHost + '_' + 'utm_campaign'
                );
                data.utm_term = KRAKEN.common.getBrowserLocalStorage(localHost + '_' + 'utm_term');
                data.utm_content = KRAKEN.common.getBrowserLocalStorage(
                    localHost + '_' + 'utm_content'
                );
                data.spender = KRAKEN.common.getBrowserLocalStorage(localHost + '_' + 'spender');
                data.spenderDomain = KRAKEN.common.getBrowserLocalStorage(
                    localHost + '_' + 'spenderDomain'
                );
                data.hasBlocker = KRAKEN.common.getBrowserLocalStorage(
                    localHost + '_' + 'hasBlocker'
                );
                data.tags = KRAKEN.common.getBrowserLocalStorage(localHost + '_' + 'tags');
                data.gclid = KRAKEN.common.getBrowserLocalStorage(localHost + '_' + 'gclid');
                data.attributes = KRAKEN.common.getBrowserLocalStorage(
                    localHost + '_' + 'attributes'
                );
                data.referrerUrl = KRAKEN.common.getBrowserLocalStorage(
                    localHost + '_' + 'referrerUrl'
                );
            }
            return data;
        },
        setLocalStorage: function (localHost) {
            try {
                KRAKEN.common.setBrowserLocalStorage(localHost + '_property', localHost);
                this.addToLocalStorage(localHost, 'utm_medium');
                this.addToLocalStorage(localHost, 'utm_source');
                this.addToLocalStorage(localHost, 'utm_campaign');
                this.addToLocalStorage(localHost, 'utm_term');
                this.addToLocalStorage(localHost, 'utm_content');
                this.addToLocalStorage(localHost, 'tags');
                this.addToLocalStorage(localHost, 'gclid');
                this.addAttributesToLocalStorage(localHost);
                this.addReferrerToLocalStorage(localHost);
                return true;
            } catch (error) {
                return false;
            }
        },
        addReferrerToLocalStorage: function (localHost) {
            var refUrl = KRAKEN.common.getBrowserLocalStorage(localHost + '_' + 'referrerUrl');
            if (!refUrl || refUrl == null) {
                KRAKEN.common.setBrowserLocalStorage(localHost + '_referrerUrl', document.referrer);
            }
        },
        setTagsvaluesForLocalStorage: function () {
            var values = this.getArrayParameterByName('tag[]');
            var value = this.getParameterByName('tag');
            if (value != null && value != 'undefined' && value != '') {
                values.push(value);
            }
            if (!values.length) {
                return null;
            }
            return JSON.stringify(values);
        },
        addAttributesToLocalStorage: function (localHost) {
            var att = this.getUrlAttributes();
            if (att != null && att != 'undefined' && att != '{}') {
                KRAKEN.common.setBrowserLocalStorage(localHost + '_attributes', att);
                return true;
            }
            return false;
        },
        getUrlAttributes: function () {
            var att = {};
            var matches;
            var pattern = 'kr\\[(.*?)\\]' + '=([^&#]+)';
            var o_reg = new RegExp(pattern, 'ig');
            while ((matches = o_reg.exec(this.url))) {
                if (matches.length == 3) {
                    att[matches[1]] = matches[2];
                } else {
                    break;
                }
            }
            return JSON.stringify(att);
        }
    };

    KRAKEN.common = {
        krakenApiVersion: 'v1',
        krakenApiHost: 'https://api.rabbits.webcam',
        localHost: window.location.hostname,
        krakenApiStatus: null,
        krakenApiStatusText: null,
        localHostQueryString: window.location.search,
        productParams: {},
        krakenTFApiHost: '//rabbits.webcam',
        krakenTFApiStatus: null,
        krakenTFApiStatusText: null,
        cookieDomain: '',
        exid: null,
        sendApiRequest: function (data, apiUri, verb) {
            var uri = this.krakenApiHost + '/' + this.krakenApiVersion + apiUri;
            var requestData = JSON.stringify(data);
            var xhr = new XMLHttpRequest();
            xhr.open(verb, uri, true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.withCredentials = true;
            xhr.send(requestData);
            xhr.onload = function () {
                this.krakenApiStatus = xhr.status;
                this.krakenApiStatusText = xhr.statusText;
            };
        },
        trailingEntity: function () {
            var verb = 'put';
            var cookie = KRAKEN.common.getExidFromCookie();
            var data = this.getEntityData();
            data.deviceType = KRAKEN.helpers.getDeviceType();
            var apiUri = KRAKEN.entity.getKrakenApiUri(cookie.exid);
            if (apiUri !== false) {
                this.sendApiRequest(data, apiUri, verb);
            }
        },
        krakEvent: 'ontouchstart' in window ? 'touchstart' : 'click',
        addEntityTrailingListener: function (entityTrailingListener) {
            if (entityTrailingListener != 'undefined') {
                for (var i = 0; i < document.links.length; i++) {
                    document.links[i].addEventListener(this.krakEvent, entityTrailingListener, {
                        passive: true
                    });
                }
            }
        },
        getEntityData: function () {
            if (KRAKEN.entity.localStorageFallback === true) {
                return this.getEntityCookie();
            }
            return KRAKEN.entity.getEntityData(this.localHost);
        },
        setEntityCookie: function () {
            KRAKEN.common.setBrowserCookie(this.localHost + '_property', this.localHost, 365);
            KRAKEN.common.setBrowserCookie(this.localHost + '_referrerUrl', document.referrer, 365);
            this.addIEntityCookie('utm_medium');
            this.addIEntityCookie('utm_source');
            this.addIEntityCookie('utm_campaign');
            this.addIEntityCookie('utm_term');
            this.addIEntityCookie('utm_content');
            this.addIEntityCookie('gclid');
        },
        addIEntityCookie: function (key) {
            var value = KRAKEN.entity.getParameterByName(key);
            if (value != null && value != 'undefined' && value != '') {
                KRAKEN.common.setBrowserCookie(this.localHost + '_' + key, value, 365);
            }
        },
        getEntityCookie: function () {
            var data = new Object();
            data.property = KRAKEN.common.getBrowserCookie(this.localHost + '_property');
            data.utm_medium = KRAKEN.common.getBrowserCookie(this.localHost + '_utm_medium');
            data.utm_source = KRAKEN.common.getBrowserCookie(this.localHost + '_utm_source');
            data.utm_campaign = KRAKEN.common.getBrowserCookie(this.localHost + '_utm_campaign');
            data.utm_term = KRAKEN.common.getBrowserCookie(this.localHost + '_utm_term');
            data.utm_content = KRAKEN.common.getBrowserCookie(this.localHost + '_utm_content');
            data.spender = KRAKEN.common.getBrowserCookie(this.localHost + '_' + 'spender');
            data.spenderDomain = KRAKEN.common.getBrowserCookie(
                this.localHost + '_' + 'spenderDomain'
            );
            data.gclid = KRAKEN.common.getBrowserCookie(this.localHost + '_' + 'gclid');
            data.referrerUrl = KRAKEN.common.getBrowserCookie(this.localHost + '_referrerUrl');
            return data;
        },
        callOnSuccess: function (responseText, _cb) {
            if (_cb && _cb.success && typeof _cb.success == 'function') {
                _cb.success(responseText);
            } else if (_cb && typeof _cb == 'function') {
                _cb(responseText);
            }
        },
        callOnError: function (responseText, _cb) {
            if (_cb && _cb.error && typeof _cb.error == 'function') {
                _cb.error(responseText);
            }
        },
        sendApiRequestWithCallback: function (data, apiUri, verb, _cb) {
            var uri = KRAKEN.common.krakenApiHost + '/' + KRAKEN.common.krakenApiVersion + apiUri;
            var xhr = new XMLHttpRequest();
            xhr.open(verb, uri, true);
            xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
            xhr.withCredentials = true;
            xhr.onload = function () {
                if (xhr.status == 202) {
                    KRAKEN.common.callOnSuccess(xhr.responseText, _cb);
                } else {
                    KRAKEN.common.callOnError(xhr.responseText, _cb);
                }
            };
            xhr.send(data);
        },
        getProductParams: function () {
            if (typeof KRAKEN.productParams !== 'undefined') {
                this.productParams = KRAKEN.productParams;
            }
        },
        checkChan: function (cmpVal) {
            if (true === this.hasDifferentReferrerHost() && null === cmpVal) {
                KRAKEN.chan.chanFinder(KRAKEN.chan.referrerUrl, KRAKEN.chan.url);
            }
        },
        hasDifferentReferrerHost: function () {
            if (this.getHostName(KRAKEN.chan.referrerUrl) !== this.getHostName(KRAKEN.chan.url)) {
                return true;
            }
            return false;
        },
        getHostName: function (url) {
            var match = url.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i);
            if (
                match != null &&
                match.length > 2 &&
                typeof match[2] === 'string' &&
                match[2].length > 0
            ) {
                return match[2];
            }
            return '';
        },
        hasDifferentReferrerDomain: function () {
            if (this.getDomain(KRAKEN.chan.referrerUrl) !== this.getDomain(KRAKEN.chan.url)) {
                return true;
            }
            return false;
        },
        getDomain: function (url) {
            var hostName = KRAKEN.common.getHostName(url);
            var domain = hostName;
            if ('' !== hostName) {
                var parts = hostName.split('.').reverse();
                if (parts != null && parts.length > 1) {
                    domain = parts[1] + '.' + parts[0];
                }
            }
            return domain;
        },
        sendApiRequestToTF: function (data, apiUri, verb, _cb) {
            var uri = this.krakenTFApiHost + apiUri;
            var xhr = new XMLHttpRequest();
            xhr.open(verb, uri, true);
            xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
            xhr.withCredentials = true;
            xhr.onload = function () {
                if (200 == xhr.status || 202 == xhr.status) {
                    KRAKEN.common.callOnSuccess(xhr.responseText, _cb);
                } else {
                    KRAKEN.common.callOnError(xhr.responseText, _cb);
                }
            };
            xhr.send(data);
        },
        ResetCurrentLoc: function (newLocationSearch) {
            if (undefined !== newLocationSearch && null !== newLocationSearch) {
                window.location.search = newLocationSearch;
            }
        },
        trail: function () {
            KRAKEN.common.setTailCallbacks();
            if (
                'undefined' !== typeof KRAKEN.productParams.creturn &&
                null !== KRAKEN.productParams.creturn
            ) {
                KRAKEN.common.tfidTrail();
            } else {
                var cmpVal = KRAKEN.sonar.validateCmp();
                KRAKEN.common.saveRequestCmp('cmp', cmpVal);
                KRAKEN.common.checkChan(cmpVal);
                KRAKEN.common.launchTrackedPageImpressionEventLog(cmpVal);
            }
        },
        tfidTrail: function () {
            if (true === KRAKEN.common.hasDifferentReferrerHost()) {
                var tfid = KRAKEN.sonar.validateTfId();
                KRAKEN.common.saveRequestCmp('cmp', tfid);
                KRAKEN.chan.chanTfDatFinder(tfid, KRAKEN.chan.referrerUrl, KRAKEN.chan.url);
            }
        },
        setTailCallbacks: function () {
            if ('undefined' === typeof KRAKEN.tfidCallback) {
                KRAKEN.tfidCallback = function () {
                    return null;
                };
            }
            if ('undefined' === typeof KRAKEN.chanCallback) {
                KRAKEN.chanCallback = function () {
                    KRAKEN.cmpchecker.cmpCheck();
                };
            }
        },
        setBrowserStorage: function (storage, key, value) {
            try {
                value = typeof value == 'number' ? value.toString() : value;
                if ('undefined' !== typeof key) {
                    storage.setItem(key, value);
                    return value;
                }
                return null;
            } catch (e) {
                return null;
            }
        },
        getBrowserStorage: function (storage, key) {
            if (typeof storage !== 'undefined') {
                return storage.getItem(key);
            }
            return null;
        },
        setBrowserLocalStorage: function (key, value) {
            return this.setBrowserStorage(localStorage, key, value);
        },
        getBrowserLocalStorage: function (key) {
            return this.getBrowserStorage(localStorage, key);
        },
        setBrowserSessionStorage: function (key, value) {
            return this.setBrowserStorage(sessionStorage, key, value);
        },
        getBrowserSessionStorage: function (key) {
            return this.getBrowserStorage(sessionStorage, key);
        },
        setBrowserCookie: function (key, value, expire) {
            if (!key) {
                return null;
            }
            value = typeof value == 'number' ? value.toString() : value;
            var d = new Date();
            d.setTime(d.getTime() + expire * 24 * 60 * 60 * 1e3);
            var expires = 'expires=' + d.toUTCString();
            //var domain = "domain=" + this.localHost;
            document.cookie = key + '=' + value + ';' + expires + ';path=/;SameSite=None; Secure';
            return value;
        },
        getBrowserCookie: function (key) {
            if (!key) {
                return null;
            }
            var name = key + '=';
            var ca = document.cookie.split(';');
            for (var i = 0; i < ca.length; i++) {
                var c = ca[i];
                while (c.charAt(0) == ' ') {
                    c = c.substring(1);
                }
                if (c.indexOf(name) == 0) {
                    return c.substring(name.length, c.length);
                }
            }
            return null;
        },
        setBrowserLocalStorageFallBack: function (key, value) {
            var result = this.setBrowserStorage(localStorage, key, value);
            return null !== result ? result : this.setBrowserCookie(key, value, 1);
        },
        // getBrowserLocalStorageFallBack: function (key) {
        //     var result = this.getBrowserStorage(localStorage, key);
        //     return null !== result ? result : this.getBrowserCookie(key);
        // },
        removeBrowserStorage: function (storage, key) {
            try {
                return this.removeBrowserStorageByKey(storage, key);
            } catch (e) {
                return false;
            }
        },
        removeBrowserStorageByKey: function (storage, key) {
            if ('undefined' !== typeof key) {
                storage.removeItem(key);
                return true;
            }
            return false;
        },
        removeBrowserLocalStorage: function (key) {
            return this.removeBrowserStorage(localStorage, key);
        },
        removeBrowserSessionStorage: function (key) {
            return this.removeBrowserStorage(sessionStorage, key);
        },
        removeBrowserCookie: function (name, path, domain) {
            if (KRAKEN.helpers.isCookieEnabled()) {
                return this.removeCookieByName(name, path, domain);
            }
            return false;
        },
        getParamFromRequest: function (key) {
            var queryString = window.location.search;
            var urlParams = new URLSearchParams(queryString);
            return urlParams.get(key);
        },
        removeCookieByName: function (name, path, domain) {
            document.cookie =
                name +
                '=' +
                (path ? ';path=' + path : '') +
                (domain ? ';domain=' + domain : '') +
                ';expires=Thu, 01 Jan 1970 00:00:01 GMT';
            return true;
        },
        removeBrowserTrailLocalStorage: function () {
            var items = ['cid', 'locationSrc', 'cmp'];
            try {
                return this.removeBrowserLocalStorageItems(items);
            } catch (e) {
                return false;
            }
        },
        removeBrowserLocalStorageItems: function (items) {
            for (var i = 0; i < items.length; i++) {
                this.removeBrowserLocalStorage(KRAKEN.common.localHost + '_' + items[i]);
            }
            return true;
        },
        runGenerateExid: function () {
            if ('undefined' === typeof KRAKEN.phoenixCallBack) {
                KRAKEN.phoenixCallBack = function () {
                    KRAKEN.helpers.handleExid();
                    KRAKEN.helpers.AppendRwExid();
                };
            }
            var exid = KRAKEN.common.getExidFromRequest();
            if (null === exid) {
                exid = KRAKEN.sonar.getExidFromRequestString();
            }
            if (null === exid) {
                KRAKEN.common.validateExidFromCookie();
            } else {
                KRAKEN.common.setBrowserCookie('u', exid, 365);
                KRAKEN.phoenixCallBack();
            }
        },
        validateExidFromCookie: function () {
            var cookie = KRAKEN.common.getExidFromCookie();
            if ('undefined' === typeof cookie.exid || '' === cookie.exid) {
                KRAKEN.common.generateNewExid();
            } else {
                KRAKEN.phoenixCallBack();
            }
        },
        setEntityLocalStorage: function (response) {
            if (response === false) {
                KRAKEN.common.setEntityCookie();
                KRAKEN.entity.localStorageFallback = true;
            }
        },
        getExidFromCookie: function () {
            var cookieExid = {};
            if (
                null !== KRAKEN.common.getBrowserCookie('u') &&
                KRAKEN.common.getBrowserCookie('u') !== ''
            ) {
                cookieExid['exid'] = KRAKEN.common.getBrowserCookie('u');
            }
            return cookieExid;
        },
        getExidFromRequest: function () {
            var requestString = KRAKEN.common.localHostQueryString;
            var urlParams = new URLSearchParams(requestString);
            var cmpQueryString = urlParams.get('exid');
            if (null !== cmpQueryString || 'null' !== cmpQueryString) {
                return cmpQueryString;
            }
            return null;
        },
        generateNewExid: function () {
            KRAKEN.phoenix.get(function (result) {
                if (KRAKEN.common.isValidFingerprintId(result)) {
                    KRAKEN.phoenix.fingerprintId = result;
                } else {
                    KRAKEN.phoenix.fingerprintId = KRAKEN.common.generateNewExidFallback(22);
                }
                KRAKEN.common.setBrowserCookie('u', KRAKEN.phoenix.fingerprintId, 365);
                KRAKEN.phoenixCallBack();
            });
        },
        isValidFingerprintId: function (fingerprintId) {
            if (
                'undefined' !== typeof fingerprintId &&
                null !== fingerprintId &&
                22 === fingerprintId.length
            ) {
                return true;
            }
            return false;
        },
        generateNewExidFallback: function (length) {
            var a = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890'.split('');
            var b = [];
            for (var i = 0; i < length; i++) {
                var j = (Math.random() * (a.length - 1)).toFixed(0);
                b[i] = a[j];
            }
            return b.join('');
        },
        launchPageImpressionsEventLog: function () {
            if (true === this.hasDifferentReferrerDomain()) {
                KRAKEN.event.handlePageImpressionsEventLog();
            }
        },
        getClickIdFromRequest: function () {
            var queryString = window.location.search;
            var urlParams = new URLSearchParams(queryString);
            return urlParams.get('clickid');
        },
        saveRequestCmp: function (key, val) {
            if (null !== val && 'null' !== val && '' !== val) {
                KRAKEN.common.setBrowserCookie(key, val, 365);
            }
        },
        launchTrackedPageImpressionEventLog: function (val) {
            if (
                null !== val &&
                'undefined' !== typeof KRAKEN.productParams.impTrail &&
                'y' === KRAKEN.productParams.impTrail
            ) {
                KRAKEN.common.launchPageImpressionsEventLog();
            }
        }
    };

    KRAKEN.helpers = {
        handleExid: function () {
            var cookie = KRAKEN.common.getExidFromCookie();
            if ('undefined' !== typeof cookie.exid && null !== cookie.exid) {
                var anchors = document.links;
                for (var i = 0; i < anchors.length; i++) {
                    var search = /\{exid\}/gi;
                    if (unescape(anchors[i].href).search(search) >= 0) {
                        anchors[i].href = unescape(anchors[i].href).replace(search, cookie.exid);
                    }
                }
            }
        },

        isPhoneSize: function () {
            return window.innerWidth < 767;
        },
        isTabletSize: function () {
            return window.innerWidth < 991;
        },
        isDesktopSize: function () {
            return window.innerWidth > 1199;
        },
        getDeviceType: function () {
            if (this.isPhoneSize()) {
                return 'm';
            } else if (this.isTabletSize()) {
                return 't';
            }
            return 'd';
        },
        checkDomain: function (url) {
            if (url.indexOf('//') === 0) {
                url = location.protocol + url;
            }
            return url
                .toLowerCase()
                .replace(/([a-z])?:\/\//, '$1')
                .split('/')[0];
        },
        isExternal: function (url) {
            return (
                (url.indexOf(':') > -1 || url.indexOf('//') > -1) &&
                this.checkDomain(location.href) !== this.checkDomain(url)
            );
        },
        isCookieEnabled: function () {
            var cookieEnabled = navigator.cookieEnabled;
            if (!cookieEnabled) {
                document.cookie = 'testcookie';
                cookieEnabled = document.cookie.indexOf('testcookie') != -1;
            }
            return cookieEnabled;
        },
        AppendRwExid: function (exid) {
            exid = this.getValidExid(exid);
            if ('undefined' !== exid && null !== exid) {
                var anchors = document.links;
                for (var i = 0; i < anchors.length; i++) {
                    anchors[i].href = this.appendRequestToLink(anchors[i].href, 'exid', exid);
                }
            }
        },
        getValidExid: function (exid) {
            if ('undefined' == exid || null == exid) {
                var cookie = KRAKEN.common.getExidFromCookie();
                if ('undefined' !== typeof cookie.exid && null !== cookie.exid) {
                    return cookie.exid;
                }
                return null;
            }
            return exid;
        },
        appendRequestToLink: function (anchor, key, request) {
            var searchRw = /rabbits\.webcam/gi;
            var searchQryString = /\?/gi;
            if (unescape(anchor).search(searchRw) >= 0) {
                if (unescape(anchor).search(searchQryString) >= 0) {
                    return anchor + '&' + key + '=' + request;
                }
                return anchor + '?' + key + '=' + request;
            }
            return anchor;
        },

        AppendRwTfid: function () {
            var tfid = KRAKEN.common.getParamFromRequest('tfid');
            if ('undefined' === tfid || null === tfid || '' === tfid) {
                tfid = KRAKEN.common.getBrowserCookie('tfid');
            }
            if ('undefined' !== tfid && null !== tfid && '' !== tfid) {
                KRAKEN.common.setBrowserCookie('tfid', tfid, 365);
                var anchors = document.links;
                for (var i = 0; i < anchors.length; i++) {
                    var search = /\{tfid\}/gi;
                    if (unescape(anchors[i].href).search(search) >= 0) {
                        anchors[i].href = unescape(anchors[i].href).replace(search, tfid);
                    }
                }
            }
        },
        AppendRwClickId: function () {
            var clickid = KRAKEN.common.getParamFromRequest('clickid');
            if ('undefined' === clickid || null === clickid || '' === clickid) {
                clickid = KRAKEN.common.getBrowserCookie('clickid');
            }
            if ('undefined' !== clickid && null !== clickid && '' !== clickid) {
                KRAKEN.common.setBrowserCookie('clickid', clickid, 365);
                var anchors = document.links;
                for (var i = 0; i < anchors.length; i++) {
                    if (anchors[i].href.indexOf('clickid=') === -1) {
                        anchors[i].href = this.appendRequestToLink(
                            anchors[i].href,
                            'clickid',
                            clickid
                        );
                    }
                }
            }
        }
    };

    KRAKEN.spender = {
        setSpenderType: function (response) {
            if (response === false) {
                KRAKEN.common.setBrowserCookie(
                    KRAKEN.common.localHost + '_spender',
                    'paysite',
                    365
                );
            } else {
                KRAKEN.common.setBrowserLocalStorage(
                    KRAKEN.common.localHost + '_spender',
                    'paysite'
                );
            }
        },
        setSpenderDomain: function (response) {
            if (response === false) {
                KRAKEN.common.setBrowserCookie(
                    KRAKEN.common.localHost + '_spenderDomain',
                    KRAKEN.spenderDomain,
                    365
                );
            } else {
                KRAKEN.common.setBrowserLocalStorage(
                    KRAKEN.common.localHost + '_spenderDomain',
                    KRAKEN.spenderDomain
                );
            }
        },
        setConfirmedSpender: function (response) {
            if (typeof KRAKEN.spenderDomain !== 'undefined' && KRAKEN.spenderDomain.length > 0) {
                this.setSpenderType(response);
                this.setSpenderDomain(response);
            }
        }
    };

    KRAKEN.ObstacleFinder = {
        create: function () {
            this.t = window.document.createElement('div');
            this.t.setAttribute(
                'class',
                'pub_300x250 pub_300x250m pub_728x90 text-ad textAd text_ad text_ads text-ads text-ad-links ad advert'
            );
            this.t.setAttribute(
                'style',
                'width: 1px !important; height: 1px !important; position: absolute !important; left: -10000px !important; top: -1000px !important;'
            );
            window.document.body.appendChild(this.t);
        },
        check: function (_cb) {
            if (this.t !== undefined) {
                this.t.remove;
            }
            var that = Object.create(this);
            window.setTimeout(function () {
                var hasBlocker = KRAKEN.ObstacleFinder.baitExists(that.t);
                if (typeof _cb == 'function') {
                    _cb(hasBlocker);
                }
            }, 200);
        },
        baitExists: function (t) {
            if (
                t.offsetParent === null ||
                t.offsetHeight === 0 ||
                t.offsetLeft === 0 ||
                t.offsetTop === 0 ||
                t.offsetWidth === 0 ||
                t.clientHeight === 0 ||
                t.clientWidth === 0
            ) {
                return true;
            }
            if (window.getComputedStyle !== undefined) {
                var tmp = window.getComputedStyle(this.t, null);
                if (
                    tmp &&
                    (tmp.getPropertyValue('display') == 'none' ||
                        tmp.getPropertyValue('visibility') == 'hidden')
                ) {
                    return true;
                }
            }
            return false;
        },
        setHasBlocker: function (hasBlocker) {
            if (hasBlocker) {
                KRAKEN.common.setBrowserLocalStorage(
                    KRAKEN.common.localHost + '_hasBlocker',
                    'true'
                );
            } else {
                KRAKEN.common.setBrowserLocalStorage(
                    KRAKEN.common.localHost + '_hasBlocker',
                    'false'
                );
            }
        }
    };

    (KRAKEN.ProductCmpPattern = {
        AFNO: '^1-([0-9]+)',
        mid: '^([0-9]+)$',
        cmp: '^(.+)$',
        track: '^([^~]+)~?',
        'prm[campaign_id]': '^(.+)$',
        c: '^(.+)$',
        tfid: '^([0-9]+)$'
    }),
        (KRAKEN.ProductCmpFormat = {
            AFNO: '1-{cmp}-{exid}',
            mid: '{cmp}',
            cmp: '{cmp}',
            track: '{cmp}~{exid}~1',
            'prm[campaign_id]': '{cmp}',
            c: '{cmp}',
            tfid: '{cmp}'
        });

    KRAKEN.chan = {
        referrerUrl: document.referrer,
        url: window.location.href,
        chanFinder: function (referrerUrl, url) {
            var verb = 'post';
            var apiUri = '/channel/';
            var data =
                'referrerUrl=' +
                encodeURIComponent(referrerUrl) +
                '&url=' +
                encodeURIComponent(url);
            KRAKEN.common.sendApiRequestWithCallback(data, apiUri, verb, KRAKEN.chan.callback);
        },
        callback: function (responseText) {
            var response = JSON.parse(responseText);
            if (response['channel']) {
                KRAKEN.cmpchecker.trafficChannel = response['channel'];
                KRAKEN.common.setBrowserLocalStorageFallBack(
                    KRAKEN.common.localHost + '_channel',
                    response['channel']
                );
            } else {
                KRAKEN.cmpchecker.trafficChannel = 'direct';
                KRAKEN.common.setBrowserLocalStorageFallBack(
                    KRAKEN.common.localHost + '_channel',
                    'direct'
                );
            }
            KRAKEN.chanCallback();
        },
        chanTfDatFinder: function (tfid, referrerUrl, url) {
            KRAKEN.chan.referrerUrl = referrerUrl;
            KRAKEN.chan.url = url;
            KRAKEN.common.getProductParams();
            KRAKEN.tfidchecker.getCurrentProductID();
            if (null !== tfid) {
                KRAKEN.tfidchecker.requestCmpByTfId(tfid);
            } else {
                KRAKEN.chan.cmpByChannelRequest(referrerUrl, url);
            }
        },
        cmpByChannelRequest: function (referrerUrl, url) {
            var verb = 'post';
            var apiUri = '/channel/';
            var data =
                'referrerUrl=' +
                encodeURIComponent(referrerUrl) +
                '&url=' +
                encodeURIComponent(url);
            KRAKEN.common.sendApiRequestWithCallback(data, apiUri, verb, KRAKEN.chan.tfIdcallback);
        },
        tfIdcallback: function (responseText) {
            var response = JSON.parse(responseText);
            if (response['channel']) {
                KRAKEN.tfidchecker.trafficChannel = response['channel'];
            } else {
                KRAKEN.tfidchecker.trafficChannel = 'direct';
            }
            KRAKEN.tfidchecker.requestCampaign();
        }
    };

    KRAKEN.sonar = {
        productCmpKey: null,
        productStorageCmpKey: null,
        getProductUrlCmpKey: function () {
            if (
                'undefined' !== typeof KRAKEN.common.productParams.cmp &&
                KRAKEN.common.productParams.cmp.length > 0
            ) {
                this.productCmpKey = KRAKEN.common.productParams.cmp;
            }
        },
        getProductStorageCmpKey: function () {
            if (
                'undefined' !== typeof KRAKEN.common.productParams.sub &&
                KRAKEN.common.productParams.sub.length > 0
            ) {
                this.productStorageCmpKey = KRAKEN.common.productParams.sub;
            }
        },
        isProductCmpCaseSensitive: function () {
            if (
                'undefined' !== typeof KRAKEN.common.productParams.isCaseSensitiveCmp &&
                'y' === KRAKEN.common.productParams.isCaseSensitiveCmp
            ) {
                return true;
            }
            return false;
        },
        validateCmp: function () {
            KRAKEN.common.getProductParams();
            this.getProductUrlCmpKey();
            this.getProductStorageCmpKey();
            return this.beginCmpValidation();
        },
        beginCmpValidation: function () {
            var cmpVal = this.beginCmpUrlValidation();
            if (null === cmpVal) {
                var cmpQueryString = this.beginCmpStotageValidation();
                cmpVal = this.validateCmpPattern(cmpQueryString);
            }
            return cmpVal;
        },
        beginCmpUrlValidation: function () {
            if (null !== this.productCmpKey) {
                var cmpVal = this.getProductCmpValInUrl();
                var hasCmp = this.hasProductCmp(cmpVal);
                KRAKEN.common.setBrowserLocalStorageFallBack(
                    KRAKEN.common.localHost + '_hasCmp',
                    hasCmp
                );
                this.saveCutrrentCmp(hasCmp, cmpVal);
                return cmpVal;
            }
            return null;
        },
        saveCutrrentCmp: function (hasCmp, cmpVal) {
            if (true === hasCmp && null !== cmpVal && null !== this.productCmpKey) {
                KRAKEN.common.setBrowserLocalStorageFallBack(
                    KRAKEN.common.localHost + '_' + this.productCmpKey,
                    cmpVal
                );
            }
        },
        beginCmpStotageValidation: function () {
            if (null !== this.productStorageCmpKey) {
                var strageCmp = KRAKEN.common.getBrowserCookie(this.productStorageCmpKey);
                if (('MemberID' === this.productStorageCmpKey) & (1 == strageCmp)) {
                    strageCmp = null;
                }
                var hasStoragedCmp = KRAKEN.sonar.validateStorageCmp(strageCmp);
                KRAKEN.common.setBrowserLocalStorageFallBack(
                    KRAKEN.common.localHost + '_hasCmp',
                    hasStoragedCmp
                );
                this.saveCutrrentCmp(hasStoragedCmp, strageCmp);
                return strageCmp;
            }
            return null;
        },
        validateStorageCmp: function (storagedCmp) {
            if (undefined !== storagedCmp && null !== storagedCmp) {
                var cmpVal = this.validateCmpPattern(storagedCmp);
                return this.hasProductCmp(cmpVal);
            }
            return false;
        },
        getProductCmpValInUrl: function () {
            var requestString = this.getRequestString();
            var urlParams = new URLSearchParams(requestString);
            var cmpQueryString = urlParams.get(this.productCmpKey);
            if (null !== cmpQueryString || 'null' !== cmpQueryString) {
                return this.validateCmpPattern(cmpQueryString);
            }
            return null;
        },
        getRequestString: function () {
            if (true === this.isProductCmpCaseSensitive()) {
                return KRAKEN.common.localHostQueryString;
            }
            return KRAKEN.common.localHostQueryString.toLowerCase();
        },
        getMatchOptions: function () {
            if (true === this.isProductCmpCaseSensitive()) {
                return 'g';
            }
            return 'ig';
        },
        validateCmpPattern: function (cmpQueryString) {
            var pattern = KRAKEN.ProductCmpPattern[this.productCmpKey];
            var regex = new RegExp(pattern, this.getMatchOptions());
            var matches = regex.exec(cmpQueryString);
            if (
                null !== matches &&
                undefined !== matches[1] &&
                null !== matches[1] &&
                'null' !== matches[1]
            ) {
                return matches[1];
            }
            return null;
        },
        hasProductCmp: function (cmpVal) {
            if (null !== cmpVal && cmpVal.length > 0) {
                return true;
            }
            return false;
        },
        validateTfId: function () {
            KRAKEN.common.getProductParams();
            this.getProductUrlCmpKey();
            this.getProductTfIdStorageKey();
            if (null !== this.productCmpKey) {
                var cmpVal = this.getProductCmpValInUrl();
                var hasCmp = this.hasProductCmp(cmpVal);
                if (hasCmp) {
                    return cmpVal;
                }
            }
            if (null !== this.productStorageCmpKey) {
                var storagedCmp = KRAKEN.common.getBrowserCookie(this.productStorageCmpKey);
                var hasStorageCmp = KRAKEN.sonar.validateStorageCmp(storagedCmp);
                if (hasStorageCmp) {
                    return storagedCmp;
                }
            }
            return null;
        },
        getProductTfIdStorageKey: function () {
            if (
                'undefined' !== typeof KRAKEN.common.productParams.storage &&
                KRAKEN.common.productParams.storage.length > 0
            ) {
                this.productStorageCmpKey = KRAKEN.common.productParams.storage;
            }
        },
        getExidFromRequestString: function () {
            /* read product parameters */
            KRAKEN.common.getProductParams();
            this.getProductUrlCmpKey();
            this.getProductStorageCmpKey();
            return this.beginExidValidation();
        },
        beginExidValidation: function () {
            var cmpQueryString = this.getCmpQueryStringInUrl();
            if (null === cmpQueryString) {
                return null;
            }
            return this.getValidExid(cmpQueryString);
        },
        getCmpQueryStringInUrl: function () {
            if (null !== this.productCmpKey) {
                var requestString = KRAKEN.common.localHostQueryString;
                var urlParams = new URLSearchParams(requestString);
                return urlParams.get(this.productCmpKey);
            }
            return null;
        },
        getValidExid: function (cmpString) {
            if (
                'undefined' !== typeof KRAKEN.productParams.cmp &&
                'AFNO' === KRAKEN.productParams.cmp
            ) {
                var pos = KRAKEN.wl.getNthIndexOfString(cmpString, 2, '-');
                if (-1 === pos) {
                    return null;
                }
                return cmpString.substring(pos + 1, pos + 23);
            }
            return null;
        }
    };

    KRAKEN.cmpchecker = {
        productID: null,
        trafficChannel: 'direct',
        newChannelCmp: null,
        previousCmp: null,
        newChannelTracking: null,
        track: null,
        cmpCheck: function () {
            KRAKEN.common.getProductParams();
            KRAKEN.cmpchecker.getCurrentProductID();
            KRAKEN.cmpchecker.getCurrentTrafficChannel();
            KRAKEN.cmpchecker.requestNewCmp();
        },
        getCurrentTrafficChannel: function () {
            // var channel = KRAKEN.common.getBrowserLocalStorageFallBack(
            //     KRAKEN.common.localHost + '_channel'
            // );
            // if (null !== channel && channel.length > 0 && channel != 'unknown') {
            //     this.trafficChannel = channel;
            // }
        },
        getCurrentProductID: function () {
            if (
                'undefined' !== typeof KRAKEN.common.productParams.id &&
                (KRAKEN.common.productParams.id > 0 || KRAKEN.common.productParams.id.length > 0)
            ) {
                this.productID = KRAKEN.common.productParams.id;
            }
        },
        requestNewCmp: function () {
            var verb = 'post';
            var apiUri = '/cmpchecker/';
            var workload = this.getWorkloadData();
            if (false !== workload) {
                KRAKEN.common.sendApiRequestWithCallback(workload, apiUri, verb, this.callback);
            }
        },
        getWorkloadData: function () {
            if (null !== this.productID && null !== this.trafficChannel) {
                return 'productID=' + this.productID + '&channel=' + this.trafficChannel;
            }
            return false;
        },
        callback: function (responseText) {
            var newCmp = JSON.parse(responseText);
            var hasNewCmp = KRAKEN.cmpchecker.getHasNewCmp(newCmp);
            var hasCampaignID = KRAKEN.cmpchecker.getHasCampaignID(newCmp);
            if (true === hasNewCmp && true === hasCampaignID) {
                KRAKEN.cmpchecker.newChannelTracking = newCmp;
                KRAKEN.cmpchecker.newChannelCmp = newCmp.cmp;
                KRAKEN.cmpchecker.track = KRAKEN.cmpchecker.buildTrackWithCmp();
                KRAKEN.common.setBrowserLocalStorageFallBack(
                    KRAKEN.common.localHost + '_hasCmp',
                    true
                );
                KRAKEN.common.setBrowserLocalStorageFallBack(
                    KRAKEN.common.localHost + '_' + KRAKEN.common.productParams.cmp,
                    KRAKEN.cmpchecker.track
                );
            }
            KRAKEN.cmpchecker.getCmpCheckerCallback();
            KRAKEN.cmpcheckerCallback();
        },
        getCmpCheckerCallback: function () {
            if ('undefined' === typeof KRAKEN.cmpcheckerCallback) {
                KRAKEN.cmpcheckerCallback = function () {
                    // KRAKEN.vclick.sendStream();
                };
            }
        },
        addCmpToAnchor: function () {
            if (
                'undefined' !== typeof KRAKEN.common.productParams.cmp &&
                null !== KRAKEN.common.productParams.cmp
            ) {
                var anchors = document.links;
                for (var i = 0; i < anchors.length; i++) {
                    if (unescape(anchors[i].href).indexOf('#') > -1) {
                        continue;
                    } else if (unescape(anchors[i].href).indexOf('?') > -1) {
                        anchors[i].href =
                            unescape(anchors[i].href) +
                            '&' +
                            KRAKEN.common.productParams.cmp +
                            '=' +
                            KRAKEN.cmpchecker.track;
                    } else {
                        anchors[i].href =
                            unescape(anchors[i].href) +
                            '?' +
                            KRAKEN.common.productParams.cmp +
                            '=' +
                            KRAKEN.cmpchecker.track;
                    }
                }
            }
        },
        buildTrackWithCmp: function () {
            var cmpVal = KRAKEN.common.productParams.cmp;
            var cmpFormat = KRAKEN.ProductCmpFormat[cmpVal];
            var validCmp = KRAKEN.cmpchecker.replaceExidOnCmpFormat(cmpFormat);
            if ('undefined' !== typeof validCmp && null !== validCmp) {
                validCmp = validCmp.replace('{cmp}', KRAKEN.cmpchecker.newChannelCmp);
            }
            return validCmp;
        },
        replaceExidOnCmpFormat: function (cmpFormat) {
            var cookie = KRAKEN.common.getExidFromCookie();
            if (
                'undefined' !== typeof cookie.exid &&
                null !== cookie.exid &&
                'undefined' !== typeof cmpFormat &&
                cmpFormat.indexOf('{exid}') > -1
            ) {
                return cmpFormat.replace('{exid}', cookie.exid);
            }
            return cmpFormat;
        },
        getHasNewCmp: function (newCmp) {
            if (undefined !== newCmp.cmp && null !== newCmp.cmp) {
                KRAKEN.common.setBrowserLocalStorageFallBack(
                    KRAKEN.common.localHost + '_cmp',
                    newCmp.cmp
                );
                KRAKEN.common.setBrowserCookie('cmp', newCmp.cmp, 365);
                return true;
            }
            return false;
        },
        getHasCampaignID: function (newCmp) {
            if (
                undefined !== newCmp.campaignID &&
                newCmp.campaignID > 0 &&
                null != newCmp.campaignID
            ) {
                KRAKEN.common.setBrowserLocalStorageFallBack(
                    KRAKEN.common.localHost + '_cid',
                    newCmp.campaignID
                );
                return true;
            }
            return false;
        }
    };

    KRAKEN.vclick = {
        // sendStream: function () {
        //     var verb = 'post';
        //     var apiUri = '/vClick';
        //     var id = KRAKEN.common.getBrowserLocalStorageFallBack(KRAKEN.common.localHost + '_cid');
        //     var product_id = KRAKEN.cmpchecker.productID;
        //     KRAKEN.vclick.getVclickCallback();
        //     var data = '';
        //     if (null !== id && null !== product_id) {
        //         data = 'id=' + id + '&product_id=' + product_id;
        //         KRAKEN.common.sendApiRequestToTF(data, apiUri, verb, KRAKEN.getVclickCallback);
        //     } else if (
        //         0 < KRAKEN.tfidchecker.returnTFdata.tfid &&
        //         null !== KRAKEN.tfidchecker.productID
        //     ) {
        //         data =
        //             'id=' +
        //             KRAKEN.tfidchecker.returnTFdata.tfid +
        //             '&product_id=' +
        //             KRAKEN.tfidchecker.productID;
        //         KRAKEN.common.sendApiRequestToTF(data, apiUri, verb, KRAKEN.getVclickCallback);
        //     }
        // },
        getVclickCallback: function () {
            if ('undefined' === typeof KRAKEN.getVclickCallback) {
                KRAKEN.getVclickCallback = function () {
                    return null;
                };
            }
        }
    };

    KRAKEN.tfidchecker = {
        productID: null,
        trafficChannel: 'direct',
        returnTFdata: {
            tfid: 0,
            cmp: null
        },
        getCurrentProductID: function () {
            if (
                'undefined' !== typeof KRAKEN.common.productParams.id &&
                (KRAKEN.common.productParams.id > 0 || KRAKEN.common.productParams.id.length > 0)
            ) {
                this.productID = KRAKEN.common.productParams.id;
            }
        },
        requestCampaign: function () {
            var verb = 'post';
            var apiUri = '/cmpchecker/';
            var workload = KRAKEN.tfidchecker.getWorkloadData();
            if (false !== workload) {
                KRAKEN.common.sendApiRequestWithCallback(
                    workload,
                    apiUri,
                    verb,
                    KRAKEN.tfidchecker.callback
                );
            }
        },
        getWorkloadData: function () {
            if (null !== this.productID && null !== this.trafficChannel) {
                return 'productID=' + this.productID + '&channel=' + this.trafficChannel;
            }
            return false;
        },
        callback: function (responseText) {
            var campaign = JSON.parse(responseText);
            if (
                undefined !== campaign.campaignID &&
                campaign.campaignID > 0 &&
                undefined !== campaign.cmp &&
                null !== campaign.cmp
            ) {
                KRAKEN.tfidchecker.returnTFdata.tfid = campaign.campaignID;
                KRAKEN.tfidchecker.returnTFdata.cmp = campaign.cmp;
                KRAKEN.common.setBrowserCookie('cmp', campaign.cmp, 365);
                if (true === KRAKEN.common.hasDifferentReferrerHost()) {
                    // KRAKEN.vclick.sendStream();
                }
                KRAKEN.tfidCallback();
            }
        },
        requestCmpByTfId: function (tfid) {
            var verb = 'post';
            var apiUri = '/cmpchecker/';
            var workload = KRAKEN.tfidchecker.getCmpByTfIdWorkload(tfid);
            if (false !== workload) {
                KRAKEN.common.sendApiRequestWithCallback(
                    workload,
                    apiUri,
                    verb,
                    KRAKEN.tfidchecker.callbackWithFallback
                );
            }
        },
        getCmpByTfIdWorkload: function (tfid) {
            if (null !== this.productID && null !== tfid) {
                return 'productID=' + this.productID + '&tfid=' + tfid;
            }
            return false;
        },
        callbackWithFallback: function (responseText) {
            var campaign = JSON.parse(responseText);
            if (
                undefined !== campaign.campaignID &&
                campaign.campaignID > 0 &&
                undefined !== campaign.cmp &&
                null !== campaign.cmp
            ) {
                KRAKEN.tfidchecker.returnTFdata.tfid = campaign.campaignID;
                KRAKEN.tfidchecker.returnTFdata.cmp = campaign.cmp;
                KRAKEN.common.setBrowserCookie('cmp', campaign.cmp, 365);
                KRAKEN.tfidCallback();
            } else {
                KRAKEN.chan.cmpByChannelRequest(KRAKEN.chan.referrerUrl, KRAKEN.chan.url);
            }
        }
    };

    KRAKEN.phoenix = {
        options: {
            sortPluginsFor: [/palemoon/i]
        },
        nativeForEach: Array.prototype.forEach,
        nativeMap: Array.prototype.map,
        fingerprintId: null,
        extend: function (source, target) {
            if (source == null) {
                return target;
            }
            for (var k in source) {
                if (source[k] != null && target[k] !== source[k]) {
                    target[k] = source[k];
                }
            }
            return target;
        },
        log: function (msg) {
            if (window.console) {
                console.log(msg);
            }
        },
        get: function (done) {
            var keys = [];
            keys = this.userAgentKey(keys);
            keys = this.languageKey(keys);
            keys = this.colorDepthKey(keys);
            keys = this.screenResolutionKey(keys);
            keys = this.timezoneOffsetKey(keys);
            keys = this.sessionStorageKey(keys);
            keys = this.localStorageKey(keys);
            keys = this.indexedDbKey(keys);
            keys = this.addBehaviorKey(keys);
            keys = this.openDatabaseKey(keys);
            keys = this.cpuClassKey(keys);
            keys = this.platformKey(keys);
            keys = this.doNotTrackKey(keys);
            // keys = this.pluginsKey(keys);
            keys = this.adBlockKey(keys);
            keys = this.hasLiedLanguagesKey(keys);
            keys = this.hasLiedResolutionKey(keys);
            keys = this.hasLiedOsKey(keys);
            keys = this.hasLiedBrowserKey(keys);
            keys = this.touchSupportKey(keys);
            var that = Object.create(this);
            this.fontsKey(keys, function (newKeys) {
                var murmur = that.x64hash128(newKeys.join('~~~'), 31);
                return done(murmur);
            });
        },
        userAgentKey: function (keys) {
            if (!this.options.excludeUserAgent) {
                keys.push(this.getUserAgent());
            }
            return keys;
        },
        getUserAgent: function () {
            return navigator.userAgent;
        },
        languageKey: function (keys) {
            if (!this.options.excludeLanguage) {
                keys.push(navigator.language);
            }
            return keys;
        },
        colorDepthKey: function (keys) {
            if (!this.options.excludeColorDepth) {
                keys.push(screen.colorDepth);
            }
            return keys;
        },
        screenResolutionKey: function (keys) {
            if (!this.options.excludeScreenResolution) {
                return this.getScreenResolution(keys);
            }
            return keys;
        },
        getScreenResolution: function (keys) {
            var resolution;
            var available;
            if (this.options.detectScreenOrientation) {
                resolution =
                    screen.height > screen.width
                        ? [screen.height, screen.width]
                        : [screen.width, screen.height];
            } else {
                resolution = [screen.height, screen.width];
            }
            if (typeof resolution !== 'undefined') {
                keys.push(resolution);
            }
            if (screen.availWidth && screen.availHeight) {
                if (this.options.detectScreenOrientation) {
                    available =
                        screen.availHeight > screen.availWidth
                            ? [screen.availHeight, screen.availWidth]
                            : [screen.availWidth, screen.availHeight];
                } else {
                    available = [screen.availHeight, screen.availWidth];
                }
            }
            if (typeof available !== 'undefined') {
                keys.push(available);
            }
            return keys;
        },
        timezoneOffsetKey: function (keys) {
            if (!this.options.excludeTimezoneOffset) {
                keys.push(new Date().getTimezoneOffset());
            }
            return keys;
        },
        sessionStorageKey: function (keys) {
            if (!this.options.excludeSessionStorage && this.hasSessionStorage()) {
                keys.push('sessionStorageKey');
            }
            return keys;
        },
        localStorageKey: function (keys) {
            if (!this.options.excludeSessionStorage && this.hasLocalStorage()) {
                keys.push('localStorageKey');
            }
            return keys;
        },
        indexedDbKey: function (keys) {
            if (!this.options.excludeIndexedDB && this.hasIndexedDB()) {
                keys.push('indexedDbKey');
            }
            return keys;
        },
        addBehaviorKey: function (keys) {
            if (document.body && !this.options.excludeAddBehavior && document.body.addBehavior) {
                keys.push('addBehaviorKey');
            }
            return keys;
        },
        openDatabaseKey: function (keys) {
            if (!this.options.excludeOpenDatabase && window.openDatabase) {
                keys.push('openDatabase');
            }
            return keys;
        },
        cpuClassKey: function (keys) {
            if (!this.options.excludeCpuClass) {
                keys.push(this.getNavigatorCpuClass());
            }
            return keys;
        },
        platformKey: function (keys) {
            if (!this.options.excludePlatform) {
                keys.push(this.getNavigatorPlatform());
            }
            return keys;
        },
        doNotTrackKey: function (keys) {
            if (!this.options.excludeDoNotTrack) {
                keys.push(this.getDoNotTrack());
            }
            return keys;
        },
        adBlockKey: function (keys) {
            if (!this.options.excludeAdBlock) {
                keys.push(this.getAdBlock());
            }
            return keys;
        },
        hasLiedLanguagesKey: function (keys) {
            if (!this.options.excludeHasLiedLanguages) {
                keys.push(this.getHasLiedLanguages());
            }
            return keys;
        },
        hasLiedResolutionKey: function (keys) {
            if (!this.options.excludeHasLiedResolution) {
                keys.push(this.getHasLiedResolution());
            }
            return keys;
        },
        hasLiedOsKey: function (keys) {
            if (!this.options.excludeHasLiedOs) {
                keys.push(this.getHasLiedOs());
            }
            return keys;
        },
        hasLiedBrowserKey: function (keys) {
            if (!this.options.excludeHasLiedBrowser) {
                keys.push(this.getHasLiedBrowser());
            }
            return keys;
        },
        fontsKey: function (keys, done) {
            return done(keys);
        },
        // pluginsKey: function (keys) {
        //     if (this.isIE()) {
        //         keys.push(this.getIEPluginsString());
        //     } else {
        //         keys.push(this.getRegularPluginsString());
        //     }
        //     return keys;
        // },
        getRegularPluginsString: function () {
            var plugins = [];
            for (var i = 0, l = navigator.plugins.length; i < l; i++) {
                plugins.push(navigator.plugins[i]);
            }
            if (this.pluginsShouldBeSorted()) {
                plugins = plugins.sort(function (a, b) {
                    if (a.name > b.name) {
                        return 1;
                    }
                    if (a.name < b.name) {
                        return -1;
                    }
                    return 0;
                });
            }
            return this.map(
                plugins,
                function (p) {
                    var mimeTypes = this.map(p, function (mt) {
                        return [mt.type, mt.suffixes].join('~');
                    }).join(',');
                    return [p.name, p.description, mimeTypes].join('::');
                },
                this
            ).join(';');
        },
        // getIEPluginsString: function() {
        //     if (window.ActiveXObject) {
        //         var names = ["AcroPDF.PDF", "Adodb.Stream", "AgControl.AgControl", "DevalVRXCtrl.DevalVRXCtrl.1", "MacromediaFlashPaper.MacromediaFlashPaper", "Msxml2.DOMDocument", "Msxml2.XMLHTTP", "PDF.PdfCtrl", "QuickTime.QuickTime", "QuickTimeCheckObject.QuickTimeCheck.1", "RealPlayer", "RealPlayer.RealPlayer(tm) ActiveX Control (32-bit)", "RealVideo.RealVideo(tm) ActiveX Control (32-bit)", "Scripting.Dictionary", "SWCtl.SWCtl", "Shell.UIHelper", "ShockwaveFlash.ShockwaveFlash", "Skype.Detection", "TDCCtl.TDCCtl", "WMPlayer.OCX", "rmocx.RealPlayer G2 Control", "rmocx.RealPlayer G2 Control.1"];
        //         return this.map(names, function(name) {
        //             try {
        //                 new ActiveXObject(name);
        //                 return name
        //             } catch (e) {
        //                 return null
        //             }
        //         }).join(";")
        //     } else {
        //         return ""
        //     }
        // },
        pluginsShouldBeSorted: function () {
            var should = false;
            for (var i = 0, l = this.options.sortPluginsFor.length; i < l; i++) {
                var re = this.options.sortPluginsFor[i];
                if (navigator.userAgent.match(re)) {
                    should = true;
                    break;
                }
            }
            return should;
        },
        touchSupportKey: function (keys) {
            if (!this.options.excludeTouchSupport) {
                keys.push(this.getTouchSupport());
            }
            return keys;
        },
        hasSessionStorage: function () {
            try {
                return !!window.sessionStorage;
            } catch (e) {
                return true;
            }
        },
        hasLocalStorage: function () {
            try {
                return !!window.localStorage;
            } catch (e) {
                return true;
            }
        },
        hasIndexedDB: function () {
            return !!window.indexedDB;
        },
        getNavigatorCpuClass: function () {
            if (navigator.cpuClass) {
                return 'navigatorCpuClass: ' + navigator.cpuClass;
            } else {
                return 'navigatorCpuClass: unknown';
            }
        },
        getNavigatorPlatform: function () {
            if (navigator.platform) {
                return 'navigatorPlatform: ' + navigator.platform;
            } else {
                return 'navigatorPlatform: unknown';
            }
        },
        getDoNotTrack: function () {
            if (navigator.doNotTrack) {
                return 'doNotTrack: ' + navigator.doNotTrack;
            } else {
                return 'doNotTrack: unknown';
            }
        },
        getTouchSupport: function () {
            var maxTouchPoints = 0;
            var touchEvent = false;
            if (typeof navigator.maxTouchPoints !== 'undefined') {
                maxTouchPoints = navigator.maxTouchPoints;
            } else if (typeof navigator.msMaxTouchPoints !== 'undefined') {
                maxTouchPoints = navigator.msMaxTouchPoints;
            }
            try {
                document.createEvent('TouchEvent');
                touchEvent = true;
            } catch (_) {
                return;
            }
            var touchStart = 'ontouchstart' in window;
            return [maxTouchPoints, touchEvent, touchStart];
        },
        getAdBlock: function () {
            var ads = document.createElement('div');
            ads.setAttribute('id', 'ads');
            document.body.appendChild(ads);
            return document.getElementById('ads') ? false : true;
        },
        getHasLiedLanguages: function () {
            if (typeof navigator.languages !== 'undefined') {
                try {
                    var firstLanguages = navigator.languages[0].substr(0, 2);
                    if (firstLanguages !== navigator.language.substr(0, 2)) {
                        return true;
                    }
                } catch (err) {
                    return true;
                }
            }
            return false;
        },
        getHasLiedResolution: function () {
            if (screen.width < screen.availWidth) {
                return true;
            }
            if (screen.height < screen.availHeight) {
                return true;
            }
            return false;
        },
        getHasLiedOs: function () {
            var userAgent = navigator.userAgent;
            var oscpu = navigator.oscpu;
            var platform = navigator.platform;
            var os;
            if (userAgent.toLowerCase().indexOf('windows phone') >= 0) {
                os = 'Windows Phone';
            } else if (userAgent.toLowerCase().indexOf('win') >= 0) {
                os = 'Windows';
            } else if (userAgent.toLowerCase().indexOf('android') >= 0) {
                os = 'Android';
            } else if (userAgent.toLowerCase().indexOf('linux') >= 0) {
                os = 'Linux';
            } else if (
                userAgent.toLowerCase().indexOf('iPhone') >= 0 ||
                userAgent.toLowerCase().indexOf('iPad') >= 0
            ) {
                os = 'iOS';
            } else if (userAgent.toLowerCase().indexOf('mac') >= 0) {
                os = 'Mac';
            } else {
                os = 'Other';
            }
            var mobileDevice;
            if (
                'ontouchstart' in window ||
                navigator.maxTouchPoints > 0 ||
                navigator.msMaxTouchPoints > 0
            ) {
                mobileDevice = true;
            } else {
                mobileDevice = false;
            }
            if (
                mobileDevice &&
                os !== 'Windows Phone' &&
                os !== 'Android' &&
                os !== 'iOS' &&
                os !== 'Other'
            ) {
                return true;
            }
            if (typeof oscpu !== 'undefined') {
                if (
                    oscpu.toLowerCase().indexOf('win') >= 0 &&
                    os !== 'Windows' &&
                    os !== 'Windows Phone'
                ) {
                    return true;
                } else if (
                    oscpu.toLowerCase().indexOf('linux') >= 0 &&
                    os !== 'Linux' &&
                    os !== 'Android'
                ) {
                    return true;
                } else if (
                    oscpu.toLowerCase().indexOf('mac') >= 0 &&
                    os !== 'Mac' &&
                    os !== 'iOS'
                ) {
                    return true;
                } else if (
                    oscpu.toLowerCase().indexOf('win') === 0 &&
                    oscpu.toLowerCase().indexOf('linux') === 0 &&
                    oscpu.toLowerCase().indexOf('mac') >= 0 &&
                    os !== 'other'
                ) {
                    return true;
                }
            }
            if (
                platform.toLowerCase().indexOf('win') >= 0 &&
                os !== 'Windows' &&
                os !== 'Windows Phone'
            ) {
                return true;
            } else if (
                (platform.toLowerCase().indexOf('linux') >= 0 ||
                    platform.toLowerCase().indexOf('android') >= 0 ||
                    platform.toLowerCase().indexOf('pike') >= 0) &&
                os !== 'Linux' &&
                os !== 'Android'
            ) {
                return true;
            } else if (
                (platform.toLowerCase().indexOf('mac') >= 0 ||
                    platform.toLowerCase().indexOf('ipad') >= 0 ||
                    platform.toLowerCase().indexOf('ipod') >= 0 ||
                    platform.toLowerCase().indexOf('iphone') >= 0) &&
                os !== 'Mac' &&
                os !== 'iOS'
            ) {
                return true;
            } else if (
                platform.toLowerCase().indexOf('win') === 0 &&
                platform.toLowerCase().indexOf('linux') === 0 &&
                platform.toLowerCase().indexOf('mac') >= 0 &&
                os !== 'other'
            ) {
                return true;
            }
            if (
                typeof navigator.plugins === 'undefined' &&
                os !== 'Windows' &&
                os !== 'Windows Phone'
            ) {
                return true;
            }
            return false;
        },
        getHasLiedBrowser: function () {
            var userAgent = navigator.userAgent;
            var productSub = navigator.productSub;
            var browser;
            if (userAgent.toLowerCase().indexOf('firefox') >= 0) {
                browser = 'Firefox';
            } else if (
                userAgent.toLowerCase().indexOf('opera') >= 0 ||
                userAgent.toLowerCase().indexOf('opr') >= 0
            ) {
                browser = 'Opera';
            } else if (userAgent.toLowerCase().indexOf('chrome') >= 0) {
                browser = 'Chrome';
            } else if (userAgent.toLowerCase().indexOf('safari') >= 0) {
                browser = 'Safari';
            } else if (userAgent.toLowerCase().indexOf('trident') >= 0) {
                browser = 'Internet Explorer';
            } else {
                browser = 'Other';
            }
            if (
                (browser === 'Chrome' || browser === 'Safari' || browser === 'Opera') &&
                productSub !== '20030107'
            ) {
                return true;
            }
            var tempRes = eval.toString().length;
            if (
                tempRes === 37 &&
                browser !== 'Safari' &&
                browser !== 'Firefox' &&
                browser !== 'Other'
            ) {
                return true;
            } else if (tempRes === 39 && browser !== 'Internet Explorer' && browser !== 'Other') {
                return true;
            } else if (
                tempRes === 33 &&
                browser !== 'Chrome' &&
                browser !== 'Opera' &&
                browser !== 'Other'
            ) {
                return true;
            }
            var errFirefox;
            try {
                throw 'a';
            } catch (err) {
                try {
                    err.toSource();
                    errFirefox = true;
                } catch (errOfErr) {
                    errFirefox = false;
                }
            }
            if (errFirefox && browser !== 'Firefox' && browser !== 'Other') {
                return true;
            }
            return false;
        },
        isIE: function () {
            if (navigator.appName === 'Microsoft Internet Explorer') {
                return true;
            } else if (navigator.appName === 'Netscape' && /Trident/.test(navigator.userAgent)) {
                return true;
            }
            return false;
        },
        each: function (obj, iterator, context) {
            if (obj === null) {
                return;
            }
            if (this.nativeForEach && obj.forEach === this.nativeForEach) {
                obj.forEach(iterator, context);
            } else if (obj.length === +obj.length) {
                for (var i = 0, l = obj.length; i < l; i++) {
                    if (iterator.call(context, obj[i], i, obj) == 'undefined') {
                        return;
                    }
                }
            } else {
                for (var key in obj) {
                    if (Object.prototype.hasOwnProperty.call(obj, key)) {
                        if (iterator.call(context, obj[key], key, obj).length == 'undefined') {
                            return;
                        }
                    }
                }
            }
        },
        map: function (obj, iterator, context) {
            var results = [];
            if (obj == null) {
                return results;
            }
            if (this.nativeMap && obj.map === this.nativeMap) {
                return obj.map(iterator, context);
            }
            this.each(obj, function (value, index, list) {
                results[results.length] = iterator.call(context, value, index, list);
            });
            return results;
        },
        x64Add: function (m, n) {
            m = [m[0] >>> 16, m[0] & 65535, m[1] >>> 16, m[1] & 65535];
            n = [n[0] >>> 16, n[0] & 65535, n[1] >>> 16, n[1] & 65535];
            var o = [0, 0, 0, 0];
            o[3] += m[3] + n[3];
            o[2] += o[3] >>> 16;
            o[3] &= 65535;
            o[2] += m[2] + n[2];
            o[1] += o[2] >>> 16;
            o[2] &= 65535;
            o[1] += m[1] + n[1];
            o[0] += o[1] >>> 16;
            o[1] &= 65535;
            o[0] += m[0] + n[0];
            o[0] &= 65535;
            return [(o[0] << 16) | o[1], (o[2] << 16) | o[3]];
        },
        x64Multiply: function (m, n) {
            m = [m[0] >>> 16, m[0] & 65535, m[1] >>> 16, m[1] & 65535];
            n = [n[0] >>> 16, n[0] & 65535, n[1] >>> 16, n[1] & 65535];
            var o = [0, 0, 0, 0];
            o[3] += m[3] * n[3];
            o[2] += o[3] >>> 16;
            o[3] &= 65535;
            o[2] += m[2] * n[3];
            o[1] += o[2] >>> 16;
            o[2] &= 65535;
            o[2] += m[3] * n[2];
            o[1] += o[2] >>> 16;
            o[2] &= 65535;
            o[1] += m[1] * n[3];
            o[0] += o[1] >>> 16;
            o[1] &= 65535;
            o[1] += m[2] * n[2];
            o[0] += o[1] >>> 16;
            o[1] &= 65535;
            o[1] += m[3] * n[1];
            o[0] += o[1] >>> 16;
            o[1] &= 65535;
            o[0] += m[0] * n[3] + m[1] * n[2] + m[2] * n[1] + m[3] * n[0];
            o[0] &= 65535;
            return [(o[0] << 16) | o[1], (o[2] << 16) | o[3]];
        },
        x64Rotl: function (m, n) {
            n %= 64;
            if (n === 32) {
                return [m[1], m[0]];
            } else if (n < 32) {
                return [(m[0] << n) | (m[1] >>> (32 - n)), (m[1] << n) | (m[0] >>> (32 - n))];
            } else {
                n -= 32;
                return [(m[1] << n) | (m[0] >>> (32 - n)), (m[0] << n) | (m[1] >>> (32 - n))];
            }
        },
        x64LeftShift: function (m, n) {
            n %= 64;
            if (n === 0) {
                return m;
            } else if (n < 32) {
                return [(m[0] << n) | (m[1] >>> (32 - n)), m[1] << n];
            } else {
                return [m[1] << (n - 32), 0];
            }
        },
        x64Xor: function (m, n) {
            return [m[0] ^ n[0], m[1] ^ n[1]];
        },
        x64Fmix: function (h) {
            h = this.x64Xor(h, [0, h[0] >>> 1]);
            h = this.x64Multiply(h, [4283543511, 3981806797]);
            h = this.x64Xor(h, [0, h[0] >>> 1]);
            h = this.x64Multiply(h, [3301882366, 444984403]);
            h = this.x64Xor(h, [0, h[0] >>> 1]);
            return h;
        },
        x64hash128: function (key, seed) {
            key = key || '';
            seed = seed || 0;
            var remainder = key.length % 16;
            var bytes = key.length - remainder;
            var h1 = [0, seed];
            var h2 = [0, seed];
            var k1 = [0, 0];
            var k2 = [0, 0];
            var c1 = [2277735313, 289559509];
            var c2 = [1291169091, 658871167];
            for (var i = 0; i < bytes; i = i + 16) {
                k1 = [
                    (key.charCodeAt(i + 4) & 255) |
                        ((key.charCodeAt(i + 5) & 255) << 8) |
                        ((key.charCodeAt(i + 6) & 255) << 16) |
                        ((key.charCodeAt(i + 7) & 255) << 24),
                    (key.charCodeAt(i) & 255) |
                        ((key.charCodeAt(i + 1) & 255) << 8) |
                        ((key.charCodeAt(i + 2) & 255) << 16) |
                        ((key.charCodeAt(i + 3) & 255) << 24)
                ];
                k2 = [
                    (key.charCodeAt(i + 12) & 255) |
                        ((key.charCodeAt(i + 13) & 255) << 8) |
                        ((key.charCodeAt(i + 14) & 255) << 16) |
                        ((key.charCodeAt(i + 15) & 255) << 24),
                    (key.charCodeAt(i + 8) & 255) |
                        ((key.charCodeAt(i + 9) & 255) << 8) |
                        ((key.charCodeAt(i + 10) & 255) << 16) |
                        ((key.charCodeAt(i + 11) & 255) << 24)
                ];
                k1 = this.x64Multiply(k1, c1);
                k1 = this.x64Rotl(k1, 31);
                k1 = this.x64Multiply(k1, c2);
                h1 = this.x64Xor(h1, k1);
                h1 = this.x64Rotl(h1, 27);
                h1 = this.x64Add(h1, h2);
                h1 = this.x64Add(this.x64Multiply(h1, [0, 5]), [0, 1390208809]);
                k2 = this.x64Multiply(k2, c2);
                k2 = this.x64Rotl(k2, 33);
                k2 = this.x64Multiply(k2, c1);
                h2 = this.x64Xor(h2, k2);
                h2 = this.x64Rotl(h2, 31);
                h2 = this.x64Add(h2, h1);
                h2 = this.x64Add(this.x64Multiply(h2, [0, 5]), [0, 944331445]);
            }
            k1 = [0, 0];
            k2 = [0, 0];
            switch (remainder) {
                case 15:
                    k2 = this.x64Xor(k2, this.x64LeftShift([0, key.charCodeAt(i + 14)], 48));
                    break;
                case 14:
                    k2 = this.x64Xor(k2, this.x64LeftShift([0, key.charCodeAt(i + 13)], 40));
                    break;
                case 13:
                    k2 = this.x64Xor(k2, this.x64LeftShift([0, key.charCodeAt(i + 12)], 32));
                    break;
                case 12:
                    k2 = this.x64Xor(k2, this.x64LeftShift([0, key.charCodeAt(i + 11)], 24));
                    break;
                case 11:
                    k2 = this.x64Xor(k2, this.x64LeftShift([0, key.charCodeAt(i + 10)], 16));
                    break;
                case 10:
                    k2 = this.x64Xor(k2, this.x64LeftShift([0, key.charCodeAt(i + 9)], 8));
                    break;
                case 9:
                    k2 = this.x64Xor(k2, [0, key.charCodeAt(i + 8)]);
                    k2 = this.x64Multiply(k2, c2);
                    k2 = this.x64Rotl(k2, 33);
                    k2 = this.x64Multiply(k2, c1);
                    h2 = this.x64Xor(h2, k2);
                    break;
                case 8:
                    k1 = this.x64Xor(k1, this.x64LeftShift([0, key.charCodeAt(i + 7)], 56));
                    break;
                case 7:
                    k1 = this.x64Xor(k1, this.x64LeftShift([0, key.charCodeAt(i + 6)], 48));
                    break;
                case 6:
                    k1 = this.x64Xor(k1, this.x64LeftShift([0, key.charCodeAt(i + 5)], 40));
                    break;
                case 5:
                    k1 = this.x64Xor(k1, this.x64LeftShift([0, key.charCodeAt(i + 4)], 32));
                    break;
                case 4:
                    k1 = this.x64Xor(k1, this.x64LeftShift([0, key.charCodeAt(i + 3)], 24));
                    break;
                case 3:
                    k1 = this.x64Xor(k1, this.x64LeftShift([0, key.charCodeAt(i + 2)], 16));
                    break;
                case 2:
                    k1 = this.x64Xor(k1, this.x64LeftShift([0, key.charCodeAt(i + 1)], 8));
                    break;
                case 1:
                    k1 = this.x64Xor(k1, [0, key.charCodeAt(i)]);
                    k1 = this.x64Multiply(k1, c1);
                    k1 = this.x64Rotl(k1, 31);
                    k1 = this.x64Multiply(k1, c2);
                    h1 = this.x64Xor(h1, k1);
                    break;
            }
            h1 = this.x64Xor(h1, [0, key.length]);
            h2 = this.x64Xor(h2, [0, key.length]);
            h1 = this.x64Add(h1, h2);
            h2 = this.x64Add(h2, h1);
            h1 = this.x64Fmix(h1);
            h2 = this.x64Fmix(h2);
            h1 = this.x64Add(h1, h2);
            h2 = this.x64Add(h2, h1);
            return (
                ('00000000' + (h1[0] >>> 0).toString(16)).slice(-6) +
                ('00000000' + (h1[1] >>> 0).toString(16)).slice(-6) +
                ('00000000' + (h2[0] >>> 0).toString(16)).slice(-5) +
                ('00000000' + (h2[1] >>> 0).toString(16)).slice(-5)
            );
        }
    };

    KRAKEN.event = {
        xApiKey: 'VDos5uM65424MgeaQrwtT70ZZLb7gAeo5wAXFVKA',
        endpoint: 'https://45pi09fb06.execute-api.us-east-1.amazonaws.com/v1/record',
        method: 'PUT',
        responseStatus: null,
        responseStatusText: null,
        ON_SCREEN_HEIGHT: 50,
        ON_SCREEN_WIDTH: 50,
        isTriggered: 0,
        handlePageImpressionsEventLog: function () {
            KRAKEN.event.sendEventLog(null, KRAKEN.event.callback());
        },
        handleEventLog: function (element) {
            if (KRAKEN.event.isOnScreen(element) && !KRAKEN.event.isTriggered) {
                KRAKEN.event.sendEventLog(element, KRAKEN.event.callback());
            }
            return this.isOnScreen(element);
        },
        sendEventLog: function (element, _cb) {
            var eventData = KRAKEN.event.getEventLog(element);
            var requestData = JSON.stringify(eventData);
            var xhr = new XMLHttpRequest();
            xhr.open(KRAKEN.event.method, KRAKEN.event.endpoint, true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.setRequestHeader('X-API-KEY', KRAKEN.event.xApiKey);
            xhr.send(requestData);
            xhr.onload = function () {
                if (xhr.status == 200) {
                    KRAKEN.common.callOnSuccess(xhr.responseText, _cb);
                } else {
                    KRAKEN.common.callOnError(xhr.responseText, _cb);
                }
                KRAKEN.event.responseStatus = xhr.status;
                KRAKEN.event.responseStatusText = xhr.statusText;
            };
        },
        getEventLog: function (element) {
            return {
                property: KRAKEN.event.getHostName(window.location.href),
                product_id:
                    undefined !== KRAKEN.productParams && undefined !== KRAKEN.productParams.id
                        ? KRAKEN.productParams.id
                        : null,
                category: element ? element.getAttribute('kes-event-category') : 'PageView',
                label: element ? element.getAttribute('kes-event-label') : null,
                action: element ? element.getAttribute('kes-event-action') : 'Impression',
                value: element ? element.getAttribute('kes-event-value') : null,
                context: element ? element.getAttribute('kes-event-context') : null,
                exid:
                    'undefined' !== typeof KRAKEN.productParams &&
                    'undefined' !== typeof KRAKEN.productParams.isWhileLabel &&
                    'y' === KRAKEN.productParams.isWhileLabel
                        ? KRAKEN.event.getWhiteLabelExid()
                        : KRAKEN.common.getBrowserCookie('u'),
                cmp:
                    'undefined' !== typeof KRAKEN.productParams &&
                    'undefined' !== typeof KRAKEN.productParams.isWhileLabel &&
                    'y' === KRAKEN.productParams.isWhileLabel
                        ? KRAKEN.common.getBrowserCookie('pmc')
                        : KRAKEN.common.getBrowserCookie('cmp'),
                page: window.location.href,
                referralurl: document.referrer,
                useragent: navigator.userAgent,
                usertimezone:
                    'undefined' !== typeof Intl
                        ? Intl.DateTimeFormat().resolvedOptions().timeZone
                        : null
            };
        },
        getWhiteLabelExid: function () {
            var exid = KRAKEN.common.getBrowserCookie('uu');
            if (null === exid || 'null' === exid) {
                exid = KRAKEN.common.getBrowserCookie('u');
            }
            return exid;
        },
        callback: function () {
            if (KRAKEN.event.responseStatus === 200) {
                KRAKEN.event.isTriggered = 1;
            }
        },
        getHostName: function (location) {
            var url = new URL(location);
            return url && url['hostname'];
        },
        isOnScreen: function (element) {
            var rect = element.getBoundingClientRect();
            if (rect.width === 0 || rect.height === 0 || element.style.opacity === '0') {
                return false;
            }
            var windowHeight = window.innerHeight || document.documentElement.clientHeight;
            var windowWidth = window.innerWidth || document.documentElement.clientWidth;
            var elementHeight = element.offsetHeight;
            var elementWidth = element.offsetWidth;
            var onScreenHeight =
                KRAKEN.event.ON_SCREEN_HEIGHT > elementHeight
                    ? elementHeight
                    : KRAKEN.event.ON_SCREEN_HEIGHT;
            var onScreenWidth =
                KRAKEN.event.ON_SCREEN_WIDTH > elementWidth
                    ? elementWidth
                    : KRAKEN.event.ON_SCREEN_WIDTH;
            var elementBottomToWindowTop = rect.top + elementHeight;
            var bottomBoundingOnScreen = elementBottomToWindowTop >= onScreenHeight;
            var elementTopToWindowBottom = windowHeight - (rect.bottom - elementHeight);
            var topBoundingOnScreen = elementTopToWindowBottom >= onScreenHeight;
            var elementRightToWindowLeft = rect.left + elementWidth;
            var rightBoundingOnScreen = elementRightToWindowLeft >= onScreenWidth;
            var elementLeftToWindowRight = windowWidth - (rect.right - elementWidth);
            var leftBoundingOnScreen = elementLeftToWindowRight >= onScreenWidth;
            return (
                bottomBoundingOnScreen &&
                topBoundingOnScreen &&
                rightBoundingOnScreen &&
                leftBoundingOnScreen
            );
        }
    };

    KRAKEN.wl = {
        url: window.location.href,
        cmp: KRAKEN.productParams.cmp,
        affParams: KRAKEN.productParams.affParams,
        subAffId: KRAKEN.productParams.subAffId,

        crProcess: function () {
            KRAKEN.wl.aweProcess();
        },
        clcProcess: function () {
            KRAKEN.wl.aweProcess();
        },
        vipProcess: function () {
            KRAKEN.wl.aweProcess();
        },
        rcProcess: function () {
            KRAKEN.wl.cbProcess();
        },
        viprcProcess: function () {
            KRAKEN.wl.cbProcess();
        },
        rccomProcess: function () {
            KRAKEN.wl.cbProcess();
        },
        aweProcess: function () {
            var uu = KRAKEN.wl.getExid();
            KRAKEN.common.saveRequestCmp('uu', uu);
            KRAKEN.wl.cmpChecking();
        },
        getExid: function () {
            var uu = KRAKEN.wl.getExidFromAffParams();
            if (null === uu) {
                uu = KRAKEN.wl.getExidFromSubAffId();
            }
            return uu;
        },
        getExidFromAffParams: function () {
            try {
                var affParamsString = KRAKEN.wl.getRequestParamString(KRAKEN.wl.affParams);
                if (null === affParamsString) {
                    return null;
                }
                affParamsString = decodeURI(affParamsString);
                affParamsString = KRAKEN.wl.getValidBase64String(affParamsString, 52);
                affParamsString = atob(affParamsString);
                var obj = JSON.parse(affParamsString);
                if (
                    'object' !== typeof obj ||
                    null === obj ||
                    'undefined' === typeof obj.subAffId
                ) {
                    return null;
                }
                return KRAKEN.wl.getSplitterString(obj.subAffId, '~', 0);
            } catch (error) {
                console.log('Invalid JSON string');
                return null;
            }
        },
        getExidFromSubAffId: function () {
            var subAffIdString = KRAKEN.wl.getRequestParamString(KRAKEN.wl.subAffId);
            if (null === subAffIdString) {
                return null;
            }
            subAffIdString = decodeURI(subAffIdString);
            return KRAKEN.wl.getSplitterString(subAffIdString, '~', 0);
        },
        getSplitterString: function (str, splitter, index) {
            if (null === str || 'null' === str) {
                return null;
            }
            var params = str.split(splitter);
            if ('undefined' === typeof params[index]) {
                return null;
            }
            return params[index];
        },
        cmpChecking: function () {
            var cmpVal = KRAKEN.wl.getRequestParamString(KRAKEN.wl.cmp);
            if (null !== cmpVal) {
                KRAKEN.common.saveRequestCmp('pmc', cmpVal);
                KRAKEN.common.launchTrackedPageImpressionEventLog(cmpVal);
            } else {
                KRAKEN.common.trail();
            }
        },
        getRequestParamString: function (param) {
            var requestString = KRAKEN.wl.getSplitterString(KRAKEN.wl.url, '?', 1);
            var urlParams = new URLSearchParams(requestString);
            return urlParams.get(param);
        },
        getValidBase64String: function (str, len) {
            if (str.length > len) {
                return str.substring(0, len);
            }
            return str;
        },
        cbProcess: function () {
            var uu = KRAKEN.wl.cbExidChecking();
            var pmc = KRAKEN.wl.cbCmpChecking();
            if (null !== pmc) {
                KRAKEN.wl.cbTrackedProcess(uu, pmc);
            } else {
                KRAKEN.common.trail();
            }
        },
        cbExidChecking: function () {
            var uu = KRAKEN.wl.getExidFromAFNO('request');
            if (null !== uu) {
                KRAKEN.common.saveRequestCmp('uu', uu);
                return uu;
            }
            var u = KRAKEN.wl.getExidFromAFNO('cookie');
            if (null !== u) {
                KRAKEN.common.saveRequestCmp('uu', u);
                return u;
            }
            return null;
        },
        getExidFromAFNO: function (source) {
            var affParamsString = KRAKEN.wl.getAFNOString(source);
            if (null === affParamsString) {
                return null;
            }
            var pos = KRAKEN.wl.getNthIndexOfString(affParamsString, 2, '-');
            if (-1 === pos) {
                return null;
            }
            return affParamsString.substring(pos + 1, pos + 23);
        },
        getAFNOString: function (source) {
            var AFNOString = null;
            if ('request' === source) {
                AFNOString = KRAKEN.wl.getRequestParamString(KRAKEN.wl.affParams);
            } else if ('cookie' === source) {
                AFNOString = KRAKEN.common.getBrowserCookie(KRAKEN.wl.affParams);
            }
            return AFNOString;
        },
        getCmpFromAFNO: function (source) {
            return KRAKEN.wl.getParamFromAFNO(source, 1);
        },
        getParamFromAFNO: function (source, index) {
            var affParamsString = KRAKEN.wl.getAFNOString(source);
            if (null === affParamsString) {
                return null;
            }
            return KRAKEN.wl.getSplitterString(affParamsString, '-', index);
        },
        cbCmpChecking: function () {
            var pmc = KRAKEN.wl.getCmpFromAFNO('request');
            if (null !== pmc) {
                return pmc;
            }
            var pmc2 = KRAKEN.wl.getCmpFromAFNO('cookie');
            if (null !== pmc2) {
                return pmc2;
            }
            return null;
        },
        cbTrackedProcess: function (uu, pmc) {
            KRAKEN.common.saveRequestCmp('pmc', pmc);
            KRAKEN.common.saveRequestCmp(
                KRAKEN.productParams.cmp,
                KRAKEN.ProductCmpFormat.AFNO.replace('{cmp}', pmc).replace('{exid}', uu)
            );
            KRAKEN.common.launchTrackedPageImpressionEventLog(pmc);
        },
        getNthIndexOfString: function (paragraph, indexOfNth, searchTerm) {
            return paragraph.indexOf(searchTerm, indexOfNth);
        }
    };

    if (KRAKEN) {
        loadPerformer();
        // window.onload = function () {
        KRAKEN.common.runGenerateExid();
        // generate tfid in cookie and handle tfid
        KRAKEN.common.trail();
        KRAKEN.helpers.AppendRwTfid();
        KRAKEN.helpers.AppendRwClickId();
        var response = KRAKEN.entity.setLocalStorage(KRAKEN.common.localHost);
        KRAKEN.common.setEntityLocalStorage(response);
        KRAKEN.spender.setConfirmedSpender(response);
        // };
    }
}
